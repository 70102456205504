import { css } from '@emotion/react'

import { zIndexes } from '~/styles/constants'
import blobMorph from '~/styles/mixins/blobMorph'

export const blob = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  height: fit-content;
  width: 90%;
  opacity: 0.1;
  z-index: ${zIndexes.under};
  ${blobMorph};
`
