import { css } from '@emotion/react'
import { transparentize } from 'polished'

import colors from '~/styles/colors'
import { HEADER_HEIGHT, borderRadius, zIndexes } from '~/styles/constants'
import blobMorph from '~/styles/mixins/blobMorph'
import boxShadow from '~/styles/mixins/boxShadow'
import gradientText from '~/styles/mixins/gradientText'
import { mqMax } from '~/styles/mixins/mediaQueries'

const TOP_SPACE = '-15%'

export const sectionIntro = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - ${HEADER_HEIGHT});

  ${mqMax.sm} {
    align-items: flex-start;
  }
`

export const revealBackground = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  user-select: none;
  pointer-events: none;
  background: ${colors.backgroundDefault};
`

export const blob = css`
  position: absolute;
  top: ${TOP_SPACE};
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  height: max-content;
  min-width: 25rem;
  width: 35%;
  max-width: 50rem;
  ${blobMorph};

  ${mqMax.sm} {
    min-width: unset;
    width: 80%;
  }
`

export const introTextContainer = css`
  text-align: center;
  margin-top: ${TOP_SPACE};

  ${mqMax.sm} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top: -20%;
  }
`

export const introTextSecondary = css`
  color: ${colors.textGray};
  font-size: 2.5rem;
  margin: 0;

  ${mqMax.sm} {
    font-size: 1.5rem;
  }
`

export const introTextPrimaryBoundries = css`
  display: flex;
  overflow: hidden;
`

export const introTextPrimary = css`
  ${gradientText};
  font-size: 7rem;
  margin-top: 0;

  ${mqMax.sm} {
    margin-top: 1rem;
    font-size: 4rem;
    line-height: 4.5rem;
  }
`
export const buttonsContainer = css`
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  
  ${mqMax.sm} {
    display: flex;
    flex-direction: column;
    bottom: 1rem;
    width: 90%;
  }
`

export const buttonLearnMore = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;

  > #divz-icon {
    font-size: 1.1rem;
    margin-left: 0.5rem;
  }

  ${mqMax.sm} {
    margin: 0;
    margin-top: 1.5rem;
  }
`

export const featuresContainer = css`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  bottom: 5%;
  
  ${mqMax.sm} {
    display: none;
    flex-direction: column;
    bottom: 0;
  }
`

export const feature = css`
  display: flex;
  align-items: center;
  width: 15rem;
  color: ${colors.textBlack};
  border-radius: ${borderRadius};
  border: 0.1rem solid ${transparentize(0.85, colors.white)};
  background: ${colors.backgroundElement};
  padding: 2rem;
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0 2rem;
  ${boxShadow};
  
  > #divz-icon {
    color: ${colors.divzBlue};
    font-size: 2rem;
    margin-right: 1.5rem;
  }

  ${mqMax.md} {
    flex-direction: column;
    text-align: center;

    > #divz-icon {
      margin: 0;
      margin-bottom: 1rem;
      font-size: 2.5rem;
    }
  }

  ${mqMax.sm} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    
    > #divz-icon {
      font-size: 2rem;
      margin: 0;
    }
  }
`
