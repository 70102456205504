import React from 'react'

import Icon from '~/components/Icon'
import ParticlesBackground from '~/components/ParticlesBackground'
import ScrollReveal from '~/components/ScrollReveal'

import * as style from './styles'

const Summary = () => {
  return (
    <div id='section-summary' css={style.sectionSummary}>
      <ParticlesBackground
        id='particles-summary'
        withMask={false}
        type='particlesHastey'
      />
      <ScrollReveal>
        <div className='content-summary'>
          <Icon
            icon='magic'
            css={style.icon}
            animatedProps={{
              trigger: 'loop',
              delay: '2000',
              style: {
                marginBottom: '-1rem',
                marginTop: '-1rem',
              },
            }}
          />
          <h1 css={style.summary}>
          Divz will help you elevate your software game by custom tailoring quick and easy software solutions that fit your needs and make your users happy!
          </h1>
        </div>
      </ScrollReveal>
    </div>
  )
}

export default Summary
